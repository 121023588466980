@use 'sass:math' as math;

@import "styles";

@media print {
  @import "@smals-webagency/portalsocsec-assets/sass/print/base";
  @import "@smals-webagency/portalsocsec-assets/sass/print/header";
  @import "@smals-webagency/portalsocsec-assets/sass/print/typo";
  @import "@smals-webagency/portalsocsec-assets/sass/print/services";
  @import "@smals-webagency/portalsocsec-assets/sass/print/alert";
  @import "@smals-webagency/portalsocsec-assets/sass/print/toc";
}